import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import degrees from 'radians-degrees'
import radians from 'degrees-radians'

import { parseResponsiveStyles, getProp } from '~/styles/helpers'

const PIXEL_INTERVAL = 100

interface Props {
	/** The `height` defines the (approx) height of the right side of the overlay. */
	height: number
	color?: string
	/** Defines to which screen width the overlay is re-calculated. */
	maxWidth?: number
	children: ReactNode
}

const StyledOverlayContainer = styled.div`
	position: relative;
	overflow: hidden;
`

const StyledOverlay = styled.div<Props>`
	display: block;
	position: absolute;
	left: -1px; /* A little overlap just in case */
	background-color: ${'#FFF'};
	transform-origin: top left;
	z-index: 2;

	${({ height, maxWidth }: Props) => {
		const intervals = Math.ceil(maxWidth! / PIXEL_INTERVAL)

		let slopeWidth: number
		let slopeHeight: number
		let angle: number
		let elementWidth: number

		const breakpoints = []
		const responsiveStyle = {
			bottom: ['0'],
			height: ['0'],
			width: ['100%'],
			transform: ['none']
		}

		for (let index = 1; index <= intervals; index += 1) {
			breakpoints.push(`${index * PIXEL_INTERVAL}px`)

			elementWidth = (index + 1) * PIXEL_INTERVAL
			slopeWidth = Math.ceil(Math.sqrt(elementWidth * elementWidth + height * height) + 100)
			angle = Math.round(degrees(Math.tan(height / elementWidth)))
			slopeHeight = Math.cos(radians(180 - 90 - angle)) * slopeWidth

			responsiveStyle.bottom.push(`-${slopeHeight}px`)
			responsiveStyle.height.push(`${slopeHeight}px`)
			responsiveStyle.width.push(`${slopeWidth}px`)
			responsiveStyle.transform.push(`rotate(-${angle}deg)`)
		}

		return parseResponsiveStyles(responsiveStyle, breakpoints)
	}}
`

const SlopedOverlay: FunctionComponent<Props> = ({ className, children, height, color, maxWidth = 2000 }: Props) => (
	<StyledOverlayContainer className={className}>
		{children}
		<StyledOverlay height={height} maxWidth={maxWidth} color={color} />
	</StyledOverlayContainer>
)

export default SlopedOverlay
