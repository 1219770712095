import React, { FunctionComponent, Fragment } from 'react'
import { graphql, PageProps } from 'gatsby'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { css } from 'styled-components'

import { ContentfulImage, ContentfulTextBlock, ContentfulSeasonPricing } from '~/types/graphql'
import Page from '~/components/Page'
import MarkdownHTML from '~/components/MarkdownHTML'
import GoogleMaps from '~/components/GoogleMaps'
import GatsbyBackgroundImage from '~/components/GatsbyBackgroundImage'
import SlopedOverlay from '~/components/SlopedOverlay'
import IndexLayout, { IndexLayoutDataProps } from '../layouts/IndexLayout'

// import VanSVG from '../../static/svg/illustrations/van.inline.svg'
// import SurferSVG from '../../static/svg/illustrations/surfer.inline.svg'
// import ShellsHangerSVG from '../../static/svg/illustrations/schelphanger.inline.svg'

import vanSvgSrc from '../../static/svg/illustrations/van.svg'
import surferSvgSrc from '../../static/svg/illustrations/surfer.svg'
import shellsHangerSvgSrc from '../../static/svg/illustrations/schelphanger.svg'

// locale is provided through PageProps
// @see: https://www.gatsbyjs.org/docs/gatsby-internals-terminology/#pagecontext
export const query = graphql`
	query($locale: String) {
		...ContentfulMetaData
		...FooterData

		introduction1: contentfulTextBlock(contentfulid: { eq: "introduction-1" }, node_locale: { eq: $locale }) {
			description {
				childMarkdownRemark {
					html
				}
			}
			title
		}
		introduction2: contentfulTextBlock(contentfulid: { eq: "introduction-2" }, node_locale: { eq: $locale }) {
			description {
				childMarkdownRemark {
					html
				}
			}
			title
		}
		pickupService: contentfulTextBlock(contentfulid: { eq: "pickup-service" }, node_locale: { eq: $locale }) {
			description {
				childMarkdownRemark {
					html
				}
			}
			title
		}
		outro: contentfulTextBlock(contentfulid: { eq: "outro" }, node_locale: { eq: $locale }) {
			description {
				childMarkdownRemark {
					html
				}
			}
			title
		}

		pricing: contentfulPricing(contentfulid: { eq: "campervan-prices" }, node_locale: { eq: $locale }) {
			seasons {
				id
				title
				prices
				description {
					description
				}
			}
			title
			disclaimer {
				childMarkdownRemark {
					html
				}
			}
		}

		introImage: contentfulImage(contentfulid: { eq: "intro" }) {
			image {
				localFile {
					childImageSharp {
						mobile: fluid(
							quality: 80
							maxWidth: 768
							maxHeight: 768
							srcSetBreakpoints: [500, 768]
							cropFocus: SOUTH
							fit: COVER
							jpegProgressive: false
						) {
							...GatsbyImageSharpFluid_withWebp
						}
						desktop: fluid(quality: 80, maxWidth: 1980, srcSetBreakpoints: [768, 1280, 1366], jpegProgressive: false) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}

		locationImage: contentfulImage(contentfulid: { eq: "location" }) {
			image {
				localFile {
					childImageSharp {
						mobile: fluid(
							quality: 80
							maxWidth: 768
							maxHeight: 768
							srcSetBreakpoints: [500, 768]
							cropFocus: SOUTH
							fit: COVER
							jpegProgressive: false
						) {
							...GatsbyImageSharpFluid_withWebp
						}
						desktop: fluid(quality: 80, maxWidth: 1980, srcSetBreakpoints: [768, 1280, 1366], jpegProgressive: false) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	}
`

type Props = {
	data: {
		introduction1: ContentfulTextBlock
		introduction2: ContentfulTextBlock
		pickupService: ContentfulTextBlock
		outro: ContentfulTextBlock
		introImage: ContentfulImage
		locationImage: ContentfulImage

		pricing: {
			seasons: ContentfulSeasonPricing[]
			title: string
			disclaimer: {
				childMarkdownRemark: {
					html: string
				}
			}
		}
	}
} & IndexLayoutDataProps &
	PageProps

const IndexPage: FunctionComponent<Props> = ({ data, location, pageContext, ...rest }) => {
	const { introduction1, introduction2, outro, pickupService, pricing, introImage, locationImage } = data

	return (
		<IndexLayout data={data} location={location} pageContext={pageContext}>
			<Page>
				<Container maxWidth="md">
					<Box mt={6} mb={6}>
						<Typography variant="h1" gutterBottom align="center">
							{introduction1.title}
						</Typography>
						<MarkdownHTML textAlign="center" html={introduction1.description.childMarkdownRemark.html} />
					</Box>
				</Container>

				<Container>
					<img
						src={vanSvgSrc}
						alt=""
						css={css`
							width: 160px;
							height: 40px;
							margin-left: auto;
							display: block;
							position: relative;
							top: 2px;
						`}
					/>
				</Container>

				<SlopedOverlay height={80}>
					<GatsbyBackgroundImage
						css={css`
							height: 60vh;
							max-height: 700px;
							min-height: 500px;
						`}
						fluid={{
							mobile: introImage.image.localFile.childImageSharp.mobile!,
							desktop: introImage.image.localFile.childImageSharp.desktop!
						}}
					/>
				</SlopedOverlay>

				<Container maxWidth="md">
					<Box mt={-2} mb={6} position="relative" zIndex={2}>
						<Typography variant="h2" color="primary" gutterBottom align="right">
							{introduction2.title}
						</Typography>
						<MarkdownHTML textAlign="right" html={introduction2.description.childMarkdownRemark.html} />
					</Box>
				</Container>

				<Container maxWidth="md">
					<Box mt={4} mb={6}>
						<Typography variant="h1" component="h2" gutterBottom align="center">
							{pricing.title}
						</Typography>

						<Grid container spacing={3}>
							{pricing.seasons.map((season) => (
								<Grid item xs={12} md={4} key={season.id}>
									<Box mb={2}>
										<Typography variant="h3" color="primary" gutterBottom align="center">
											{season.title}
										</Typography>
										<Typography variant="body1" align="center">
											{season.description.description}
										</Typography>
										{season.prices
											.map((price) => price.match(/^"(.*)"="(.*)"$/))
											.map((match) => {
												if (!match) return null
												const [price, duration, cost] = match
												return (
													<Box key={price} textAlign="center" mb={-1}>
														<Typography variant="body1" display="inline" color="primary">
															{duration}
														</Typography>
														<Typography variant="button" display="inline" color="primary">
															&nbsp;{cost}
														</Typography>
													</Box>
												)
											})}
									</Box>
								</Grid>
							))}
						</Grid>

						<MarkdownHTML mt={4} textAlign="center" html={pricing.disclaimer.childMarkdownRemark.html} />
					</Box>
				</Container>

				<Container>
					<img
						src={surferSvgSrc}
						alt=""
						css={css`
							width: 160px;
							height: 40px;
							display: block;
							position: relative;
							top: 7px;
						`}
					/>
				</Container>

				<GatsbyBackgroundImage
					css={css`
						height: 60vh;
						max-height: 700px;
						min-height: 500px;
					`}
					fluid={{
						mobile: locationImage.image.localFile.childImageSharp.mobile!,
						desktop: locationImage.image.localFile.childImageSharp.desktop!
					}}
				/>

				<Container maxWidth="md">
					<Box mt={5} mb={7}>
						<Typography variant="h1" component="h2" gutterBottom align="center">
							{pickupService.title}
						</Typography>
						<MarkdownHTML textAlign="center" html={pickupService.description.childMarkdownRemark.html} />
					</Box>
				</Container>

				<GoogleMaps />

				<Container
					maxWidth="md"
					css={css`
						position: relative;
					`}
				>
					<img
						src={shellsHangerSvgSrc}
						alt=""
						css={css`
							width: 60px;
							height: 200px;
							display: block;
							position: absolute;
							right: -60px;
							top: -48px;
							z-index: -1;
						`}
					/>
					<Box mt={5} mb={6}>
						<Typography variant="h1" component="h2" gutterBottom align="center">
							{outro.title}
						</Typography>
						<MarkdownHTML textAlign="center" html={outro.description.childMarkdownRemark.html} />
					</Box>
				</Container>
			</Page>
		</IndexLayout>
	)
}

export default IndexPage
