import React, { FunctionComponent } from 'react'
import loadable from '@loadable/component'
// import GoogleMapReact from 'google-map-react'
import { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

const GoogleMapReact = loadable(() => import('google-map-react'))

const options = {
	panControl: false,
	mapTypeControl: false,
	scrollwheel: false,
	styles: [
		{
			elementType: 'geometry',
			stylers: [
				{
					color: '#ebe3cd'
				}
			]
		},
		{
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#523735'
				}
			]
		},
		{
			elementType: 'labels.text.stroke',
			stylers: [
				{
					color: '#f5f1e6'
				}
			]
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#c9b2a6'
				}
			]
		},
		{
			featureType: 'administrative.land_parcel',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#dcd2be'
				}
			]
		},
		{
			featureType: 'administrative.land_parcel',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#ae9e90'
				}
			]
		},
		{
			featureType: 'landscape.natural',
			elementType: 'geometry',
			stylers: [
				{
					color: '#dfd2ae'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{
					color: '#dfd2ae'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#93817c'
				}
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#a5b076'
				}
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#447530'
				}
			]
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f5f1e6'
				}
			]
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					color: '#fdfcf8'
				}
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f8c967'
				}
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#e9bc62'
				}
			]
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'geometry',
			stylers: [
				{
					color: '#e98d58'
				}
			]
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#db8555'
				}
			]
		},
		{
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#806b63'
				}
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry',
			stylers: [
				{
					color: '#dfd2ae'
				}
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#8f7d77'
				}
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					color: '#ebe3cd'
				}
			]
		},
		{
			featureType: 'transit.station',
			elementType: 'geometry',
			stylers: [
				{
					color: '#dfd2ae'
				}
			]
		},
		{
			featureType: 'water',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#b9d3c2'
				}
			]
		},
		{
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#92998d'
				}
			]
		}
	]
}

interface Props {
	// children: ReactNode
}

const GoogleMaps: FunctionComponent<Props> = ({}: Props) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '100px 0px'
	})

	return (
		<div
			ref={ref}
			css={css`
				width: 100vw;
				height: 400px;
				@media (min-width: 641px) {
					height: 60vh;
				}
			`}
		>
			{inView ? (
				<GoogleMapReact
					defaultCenter={{ lat: 37.075643, lng: -8.885433 }}
					defaultZoom={11}
					options={options}
					bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API!, language: 'nl' }}
				/>
			) : null}
		</div>
	)
}

export default GoogleMaps
